import Intro from "./Intro/Intro";
import ShopQuests from "./ShopQuests/ShopQuests";
import QuestGuide from "./QuestGuide/QuestGuide";
import { useState } from "react";
import TradeLink from "./TradeLink/TradeLink";
import { Navigate, useNavigate } from "react-router-dom";
import { useOnboardingPassed } from "@shared/hooks";

export const Welcome = () => {
  const [page, setPage] = useState(1);
  const { markOnboardingPassed } = useOnboardingPassed();
  const isRockets = process.env.REACT_APP_BRAND === 'rockets';

  const navigate = useNavigate();

  const handleNextPage = () => {
    const newPage = page + 1;
    setPage(newPage);

    if (isRockets) {
      if (newPage === 3) {
        markOnboardingPassed();
        navigate('/');
      }
    }
  };

  const handlePreviousPage = () => {
    const newPage = page - 1;
    setPage(newPage);
  };


  if (isRockets) {
    return (
      <div>
        {page === 1 && <Intro onClickNext={handleNextPage} />}
        {page === 2 && (
          <ShopQuests
            onClickPrevious={handlePreviousPage}
            onClickNext={handleNextPage}
          />
        )}
      </div>
    );
  }

  return (
    <div>
      {page === 1 && <Intro onClickNext={handleNextPage} />}
      {page === 2 && (
        <ShopQuests
          onClickPrevious={handlePreviousPage}
          onClickNext={handleNextPage}
        />
      )}
      {page === 3 && (
        <QuestGuide
          onClickPrevious={handlePreviousPage}
          onClickNext={handleNextPage}
        />
      )}
      {page === 4 && (
        <TradeLink
          onClickPrevious={handlePreviousPage}
          onClickNext={handleNextPage}
        />
      )}
    </div>
  );
};
