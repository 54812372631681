import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Modal } from '@shared/components';
import { Button } from '@shared/components';
import { useModals } from '@shared/contexts/modal-context';
import { ModalName } from '../types';
import { loginSuccess } from '@store/slices/auth/authSlice';
import { LoginFormData, LoginFormErrors, LoginType } from './types';
import { LoginTabs } from './components/LoginTabs';
import { PasswordInput } from './components/PasswordInput';
import { LoginInput } from './components/LoginInput';
import styles from './LoginModal.module.scss';
import axios from 'axios';

export const LoginModal = () => {
  const { t } = useTranslation(['main', 'errors', 'reg']);
  const { isModalOpen, closeModal, openModal } = useModals();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [formData, setFormData] = useState<LoginFormData>({
    login: '',
    password: '',
    loginType: 'phone',
  });
  const [errors, setErrors] = useState<LoginFormErrors>({});
  const [isLoading, setIsLoading] = useState(false);

  const [focusedInput, setFocusedInput] = useState<'phone' | 'email' | 'account' | 'password' | null>(null);

  const phoneRef = useRef(null);
  const inputRef = useRef(null);

  const [isLoginValid, setLoginValid] = useState(true);
  const [isPasswordValid, setPasswordValid] = useState(true);

  const handleLoginTypeChange = (type: LoginType) => {
    setFormData(prev => ({ ...prev, loginType: type, login: '' }));
    setErrors({});
    setLoginValid(true);
  };

  const handleLoginChange = (value: string) => {
    setFormData(prev => ({ ...prev, login: value }));

    if (formData.loginType === 'phone') {
      setLoginValid(value.length === 11 || value.length === 12);
    } else {
      setLoginValid(Boolean(value.length));
    }
  };

  const handlePasswordChange = (value: string) => {
    setFormData(prev => ({ ...prev, password: value }));
    setPasswordValid(Boolean(value.length));
  };

  const isFormValid = isLoginValid && isPasswordValid;

  const handleSubmit = async () => {
    if (!isLoginValid || !isPasswordValid) return;

    setErrors({});
    setIsLoading(true);

    try {
      const responseGR8 = await axios.post(`${process.env.REACT_APP_GR8_URL}/login`, {
        'login': formData.login,
        'password': formData.password
      },
        {
          headers: {
            'X-API-KEY': process.env.REACT_APP_API_KEY,
            'Content-Type': 'application/json'
          }
        })
      if (responseGR8.data) {
        try {
          const response = await axios.post(`${process.env.REACT_APP_API_URL}/login_by_site`, {
            pm_id: responseGR8.data.accountInfo.number,
            phone: responseGR8.data.accountInfo.phone
          }, {
            withCredentials: true,
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json'
            }
          })

          if (response.data.status === 'success') {
            dispatch(loginSuccess({
              isAuthenticated: true,
              user: response.data.userInfo
            }))

            localStorage.setItem('auth_token', response.data.meta.access_token)

            if (!response.data.userInfo.onboarding_passed) {
              navigate('/welcome')
            }

            closeModal()
          }

        } catch (error) {
          console.error(error);
        }
      }
    } catch (error: any) {
      if (error.response) {
        setErrors({
          general: t('errors:no_account')
        });
      } else {
        setErrors({
          general: t('errors:error_data')
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const isRockets = process.env.REACT_APP_BRAND === 'rockets';

  const regLink = () => {
    return (
      <Link className={styles.link} to={'https://parik-24.io/regmail/'} target='_blank'>
        {t('to_register')}
      </Link>
    )
  }

  return (
    <Modal isOpen={isModalOpen(ModalName.LOGIN)} onClose={closeModal}>
      <div className={styles.title}>
        {t('enter')}
      </div>

      <div className={styles.wrapper}>
        {errors.general && (
          <div className={styles.errorMessage}>
            {errors.general}
          </div>
        )}

        <LoginTabs
          activeType={formData.loginType}
          onChange={handleLoginTypeChange}
        />

        <LoginInput
          type={formData.loginType}
          value={formData.login}
          onChange={handleLoginChange}
          onFocus={() => setFocusedInput(formData.loginType)}
          onBlur={() => setFocusedInput(null)}
          isValid={isLoginValid}
          ref={phoneRef}
          inputRef={inputRef}
          isFocused={focusedInput === formData.loginType}
        />

        <PasswordInput
          value={formData.password}
          onChange={handlePasswordChange}
          onFocus={() => setFocusedInput('password')}
          onBlur={() => setFocusedInput(null)}
          isValid={isPasswordValid}
          isFocused={focusedInput === 'password'}
        />

        <div className={styles.row}>
          <Button wide title={t('to_enter')} disabled={isLoading || !isFormValid} onClick={handleSubmit} />
        </div>

        <div className={styles.row}>
          {t('reg:no_account')} &nbsp;
          {
            isRockets ? regLink() :
              <span onClick={() => openModal(ModalName.REGISTER)}>
                {t('to_register')}
              </span>
          }
        </div>

        <div className={styles.row}>
          <span onClick={() => openModal(ModalName.HELP)}>
            {t('enter_help')}
          </span>
        </div>

        <div className={styles.rulesWrapper}>
          {t('reg:by_accepting_reg')}
          <div className={styles.rules} onClick={() => openModal(ModalName.OFFER_RULES)}>{t('reg:rules_terms')}</div>
        </div>
      </div>
    </Modal>
  );
};
