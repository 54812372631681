import styles from "./Shop.module.scss";
import Product from "../../components/Product/Product";
import { useEffect, useState } from "react";
import arrow from "../../assets/images/icons/arrowup.svg";
import axios from "axios";
import { useTranslation } from "react-i18next";
import arrowFilter from "../../assets/images/icons/arrow_filter.svg";
import close from "../../assets/images/icons/close-small.svg";
import closeWhite from "../../assets/images/icons/close-white.svg";
import filter from "../../assets/images/icons/filter.svg";
import Filter from "./Filter/Filter";
import { useSelector } from "react-redux";
import { FilterIcon, RemoveIcon } from "@/shared/icons";
import { Faq } from "../Faq";

const PER_PAGE = 8;

const HomeRockets = () => {
  const [showLoader, setShowLoader] = useState(false);

  const [pagination, setPagination] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [selectedGames, setSelectedGames] = useState([]);
  const [selectedRarity, setSelectedRarity] = useState([]);
  const [selectedReaBonusType, setSelectedReaBonusType] = useState([]);
  const { t } = useTranslation("main");

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const [products, setProducts] = useState([]);

  const gameNames = {
    dota2: "Dota 2",
    csgo: "CS 2",
  };

  const rarityNames = {
    common: t("common"),
    rare: t("rare"),
    epic: t("epic"),
    legendary: t("legendary"),
  };

  const typeNames = {
    rea_bonus: "Freebet",
  };

  const reaBonusTypeNames = {
    free_bet: "Sport",
    free_spin: "Casino",
  };

  const orderedGames = ["dota2", "csgo"];
  const orderedRarities = ["common", "rare", "epic", "legendary"];
  const orderedTypes = ["rea_bonus"];
  const orderedReaBonusType = ["free_bet", "free_spin"];

  const fetchData = async (
    page,
    order = "asc",
    games = selectedGames,
    rarities = selectedRarity,
    types = selectedTypes,
    rea_bonus_type = selectedReaBonusType
  ) => {
    try {
      const token = localStorage.getItem('auth_token')
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/shop_items`,
        {
          params: {
            per_page: PER_PAGE,
            page: page,
            order: order,
            type: types,
            game: games,
            rarity: rarities,
            rea_bonus_type: rea_bonus_type
          },
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            ...(token ? { Authorization: `Bearer ${token}` } : {})
          },
        }
      );
      if (response.data) {
        if (page === 1) {
          setProducts(response.data.data);
        } else {
          setProducts((prevProducts) => [
            ...prevProducts,
            ...response.data.data,
          ]);
        }
        setPagination(response.data.pagination);
        setTotalItemsCount(response.data.pagination.total_count);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("По возрастанию");

  useEffect(() => {
    fetchData(1, selectedFilter === "По возрастанию" ? "asc" : "desc");
  }, [selectedFilter, selectedGames, selectedRarity, selectedTypes, selectedReaBonusType]);

  const next = () => {
    if (pagination && pagination.next_page) {
      setCurrentPage(currentPage + 1);
      fetchData(
        pagination.next_page,
        selectedFilter === "По возрастанию" ? "asc" : "desc"
      );
    }
  };

  const displayedItemsCount = currentPage * PER_PAGE;

  const additionalItemsCount = totalItemsCount - displayedItemsCount;

  const setLoader = () => {
    setShowLoader(true);
    const timer = setTimeout(() => {
      setShowLoader(false);
    }, 500);

    return () => clearTimeout(timer);
  };

  useEffect(() => {
    setLoader();
  }, []);

  const selectFilter = (filter) => {
    setSelectedFilter(filter);
    setIsFilterOpen(false);
    setCurrentPage(1);
    fetchData(
      1,
      filter === "По возрастанию" ? "asc" : "desc",
      selectedGames,
      selectedRarity,
      selectedTypes,
      selectedReaBonusType
    );
  };

  const toggleFilter = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  const selectGameChip = (game) => {
    setCurrentPage(1);
    setSelectedGames((prevSelected) => {
      const newSelected = prevSelected.includes(game)
        ? prevSelected.filter((item) => item !== game)
        : [...prevSelected, game];

      fetchData(
        1,
        selectedFilter === "По возрастанию" ? "asc" : "desc",
        newSelected,
        selectedRarity,
        selectedTypes,
        selectedReaBonusType
      );
      return newSelected;
    });
  };

  const selectRarityChip = (rarity) => {
    setCurrentPage(1);
    setSelectedRarity((prevSelected) => {
      const newSelected = prevSelected.includes(rarity)
        ? prevSelected.filter((item) => item !== rarity)
        : [...prevSelected, rarity];

      fetchData(
        1,
        selectedFilter === "По возрастанию" ? "asc" : "desc",
        selectedGames,
        newSelected,
        selectedTypes,
        selectedReaBonusType
      );
      return newSelected;
    });
  };

  const selectTypeChip = (type) => {
    setCurrentPage(1);
    setSelectedTypes((prevSelected) => {
      const newSelected = type === prevSelected ? "" : type;

      fetchData(
        1,
        selectedFilter === "По возрастанию" ? "asc" : "desc",
        selectedGames,
        selectedRarity,
        newSelected,
        selectedReaBonusType
      );
      return newSelected;
    });
  };

  const selectReaBonusType = (reaBonusType) => {
    setCurrentPage(1);
    setSelectedReaBonusType((prevSelected) => {
      const newSelected = reaBonusType === prevSelected ? "" : reaBonusType;

      fetchData(
        1,
        selectedFilter === "По возрастанию" ? "asc" : "desc",
        selectedGames,
        selectedRarity,
        newSelected,
        selectedReaBonusType
      );
      return newSelected;
    });
  };

  const resetFilters = () => {
    setSelectedGames([]);
    setSelectedRarity([]);
    setSelectedTypes([]);
    setSelectedReaBonusType([]);
    setCurrentPage(1);
    setSelectedFilter("По возрастанию");
    fetchData(1, "asc", [], [], []);
  };

  const [gamesOpen, setGamesOpen] = useState(false);

  const openGames = () => {
    setGamesOpen(!gamesOpen);
  };

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  const publicOfferAccepted = useSelector(
    (state) => state?.auth?.user?.public_offer_accepted
  );



  return (
    <>
      <div>
        <div className={`${styles.container} container-main`}>
          <div>
            <div className={styles.container__title}>
              <div className={styles.title}>{t("shop")}</div>
            </div>

            <div className={styles.container__filter}>
              <div className={styles.chips}>
                <div className={styles.chips__container}>
                  {orderedReaBonusType.map((reaBonusType) => (
                    <div
                      key={reaBonusType}
                      className={`${styles.chips__container_item} ${selectedReaBonusType.includes(reaBonusType)
                        ? styles.selected_chips__container_item
                        : ""
                        }`}
                      onClick={() => selectReaBonusType(reaBonusType)}
                    >
                      {reaBonusTypeNames[reaBonusType]}
                      <img src={close} alt="" />
                    </div>
                  ))}
                </div>
                <div className={styles.chips__container_divider}>|</div>
                <div className={styles.chips__container}>
                  {orderedRarities.map((rarity) => (
                    <div
                      key={rarity}
                      className={`${styles.chips__container_item} ${selectedRarity.includes(rarity)
                        ? styles.selected_chips__container_item
                        : ""
                        }`}
                      onClick={() => selectRarityChip(rarity)}
                    >
                      {rarityNames[rarity]}
                      <img src={close} alt="" />
                    </div>
                  ))}
                </div>
                <div className={styles.chips__container_divider}>|</div>
                <div onClick={resetFilters} className={styles.reset_filter}>
                  {t("reset_filters")}
                  <img src={closeWhite} alt="" style={{ placeSelf: "end" }} />
                </div>
              </div>
              <div className={styles.filter} onClick={toggleFilter}>
                {t("product_filter")}
                <img src={arrowFilter} alt="" />
                {isFilterOpen && (
                  <div className={styles.filterWrapper}>
                    <div
                      className={`${styles.filterItem} ${selectedFilter === "По возрастанию"
                        ? styles.selectedFilter
                        : ""
                        }`}
                      onClick={() => selectFilter("По возрастанию")}
                    >
                      {t("to_increase")}
                    </div>
                    <div
                      className={`${styles.filterItem} ${selectedFilter === "По убыванию"
                        ? styles.selectedFilter
                        : ""
                        }`}
                      onClick={() => selectFilter("По убыванию")}
                    >
                      {t("to_decrease")}
                    </div>
                  </div>
                )}
              </div>
              <div
                className={`${styles.filter} ${styles.filterModal}`}
                onClick={openGames}
              >
                Фильтр
                <img src={filter} alt="" />
              </div>
            </div>

            <div className={styles.mobFilter}>
              <div className={styles.mobFilter__games}>
                <div className={`${styles.mobFilter__game} ${selectedReaBonusType.includes("free_bet") ? styles.active : ""}`} onClick={() => selectReaBonusType("free_bet")}>
                  Sport
                  {selectedReaBonusType.includes("free_bet") && <RemoveIcon />}
                </div>
                <div className={`${styles.mobFilter__game} ${selectedReaBonusType.includes("free_spin") ? styles.active : ""}`} onClick={() => selectReaBonusType("free_spin")}>
                  Casino
                  {selectedReaBonusType.includes("free_spin") && <RemoveIcon />}
                </div>
              </div>
              <div onClick={openGames}>
                <FilterIcon />
              </div>
            </div>
          </div>
          <div className={styles.wrapper}>
            {showLoader ? (
              <div className={styles.loaderContainer}>
                <div>
                  {t("loading")}
                  <div className={styles.loaderWrapper}>
                    <div className={styles.loader}></div>
                  </div>
                </div>
              </div>
            ) : products.length ? (
              products.map((quest) => (
                <Product
                  key={quest.id}
                  quest={quest}
                />
              ))
            ) : (
              <div className={styles.empty}>
                <div>{t("nothing_here")}</div>
                <div
                  className={styles.emptyInfo}
                  dangerouslySetInnerHTML={{ __html: t("try_another_filters") }}
                ></div>
              </div>
            )}
          </div>
          <div className={styles.more}>
            {!showLoader && additionalItemsCount > 0 && (
              <div onClick={next}>
                <button className={styles.down_button}> {t("more")} </button>
              </div>
            )}
            {!showLoader && products.length ? (
              <div onClick={scrollToTop} className={styles.up}>
                <img src={arrow} alt="" />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <Filter
          filterOpen={gamesOpen}
          selectFilter={selectFilter}
          selectedFilter={selectedFilter}
          orderedGames={orderedGames}
          selectedGames={selectedGames}
          selectGameChip={selectGameChip}
          gameNames={gameNames}
          orderedRarities={orderedRarities}
          selectedRarity={selectedRarity}
          selectRarityChip={selectRarityChip}
          rarityNames={rarityNames}
          orderedTypes={orderedTypes}
          selectedTypes={selectedTypes}
          selectTypeChip={selectTypeChip}
          typeNames={typeNames}
          resetFilters={resetFilters}
          openGames={openGames}
          selectedReaBonusType={selectedReaBonusType}
          selectReaBonusType={selectReaBonusType}
        />
      </div>
      <Faq />
    </>
  );
};

export default HomeRockets;
