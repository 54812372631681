import { useTranslation } from 'react-i18next';
import { LoginType } from '../../types';
import styles from './LoginTabs.module.scss';

interface LoginTabsProps {
  activeType: LoginType;
  onChange: (type: LoginType) => void;
}

export const LoginTabs = ({ activeType, onChange }: LoginTabsProps) => {
  const { t } = useTranslation('main')

  return (
    <div className={styles.select}>
      <div
        className={`${styles.selectItem} ${activeType === 'phone' && styles.selected}`}
        onClick={() => onChange('phone')}
      >
        Телефон
      </div>
      <div
        className={`${styles.selectItem} ${activeType === 'account' && styles.selected}`}
        onClick={() => onChange('account')}
      >
        {t('account')}
      </div>
      <div
        className={`${styles.selectItem} ${activeType === 'email' && styles.selected}`}
        onClick={() => onChange('email')}
      >
        E-mail
      </div>
    </div>
  );
}; 