import WelcomeHeader from "../WelcomeHeader/WelcomeHeader";
import styles from "./ShopQuests.module.scss";
import { useTranslation } from "react-i18next";
import pic from "../../../assets/images/shop_quest_pic.png";

const ShopQuests = ({ onClickPrevious, onClickNext }) => {
  const { t } = useTranslation(["main", "welcome"]);

  return (
    <div className={`${styles.container}`}>

      <div className={styles.wrapper}>
        <div>
          <div className={styles.title}>
            {t("welcome:shop_and_quests_shop")}{" "}
            <span className={styles.yellow}>
              {t("welcome:shop_and_quests_quests")}
            </span>
          </div>
          <div className={styles.info}>
            <div>{t("welcome:shop_and_quests_text1")}</div>
            <div className={styles.txt}>
              {t("welcome:shop_and_quests_text2")}
            </div>
            <div className={styles.txt}>
              {t("welcome:shop_and_quests_text3")}
            </div>
          </div>
          <div className={styles.button_group}>
            <div onClick={onClickPrevious}>
              <button className={styles.btn_back}>{t("back")}</button>
            </div>
            <div onClick={onClickNext}>
              <button className={styles.btn}>{t("next")}</button>
            </div>
          </div>
        </div>

        <img src={pic} className={styles.pic} alt="Shop Quest" />
      </div>
    </div>
  );
};

export default ShopQuests;
