import './App.scss';
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import { ToastContainer } from 'react-toastify';
import Hotjar from '@hotjar/browser'
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AppRoutes } from 'routes';
import { ModalProvider } from '@shared/contexts/modal-context';

function App() {

  useEffect(() => {
    if (process.env.REACT_APP_ENV === 'production') {
      Hotjar.init(Number(process.env.REACT_APP_HOTJAR_ID), Number(process.env.REACT_APP_HOTJAR_SNIPPET_VERSION));
    }
  }, []);

  return (
    <ModalProvider>
      <BrowserRouter>
        <div>
          <AppRoutes />
          {/* <ScrollToTop /> */}
          <ToastContainer />
        </div>
      </BrowserRouter>
    </ModalProvider>
  );
}

export default App;
