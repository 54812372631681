import React from "react";
import styles from "./Banner.module.scss";
import banner_pic_mob from "@assets/images/adren.png";
import { useTranslation } from "react-i18next";
import { ModalName } from "@/shared/components";
import { useModals } from "@/shared/contexts";
import bg_desktop from "@assets/images/bg_desktop.png";

export const Banner = () => {
  const { t } = useTranslation("banner");

  const { openModal } = useModals();

  const handleLoginClick = () => {
    openModal(ModalName.LOGIN);
  };

  return (
    <div className={styles.wrapper} style={{ backgroundImage: `url(${bg_desktop})` }}>
      <div className={styles.info}>
        <div className={styles.section}>
          <div className={styles.group}>
            <h1 className={styles.title}>
              {t("title1")}{" "}
              <span style={{ color: "#F2F740" }}>{t("title1_hg")}</span>
            </h1>
            <h1 className={styles.title}>
              {t("title2")}{" "}
              <span style={{ color: "#F2F740" }}>{t("title2_hg")}</span>
            </h1>
            <h1 className={styles.title}>{t("title3")}</h1>
            <h1 className={styles.title}>
              <span style={{ color: "#F2F740" }}>{t("title3_hg")}</span>{" "}
              {t("title3_suffix")}
            </h1>

            <h1 className={styles.title2}>
              {t("title4")}{' '}{t("title5")}{' '}{t("title5_hg")}{' '}
              <span style={{ color: "#F2F740" }}>{t("title6")}</span>{" "}{t("title7")}
            </h1>
            <button className={styles.btn} onClick={handleLoginClick}>
              {t("button")}
            </button>
          </div>
          <div className={styles.banner_pic_wrapper}>
            <img src={banner_pic_mob} alt="Banner" />
          </div>
        </div>
      </div>
    </div>
  );
};
