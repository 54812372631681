import React, { useEffect, useState } from "react";
import styles from "./MobMenu.module.scss";
import { MobMenuIcon, ChevronIcon } from "@shared/icons";
import { NavLink, useLocation } from "react-router-dom";
import { Routes } from "@/routes/constants";
import { useSelector } from "react-redux";
import { RootState } from "@/store";
import { useTranslation } from "react-i18next";

export const MobMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const [active, setActive] = useState<string | null>(null);

  const handleOpen = () => {
    setIsOpen(!isOpen);
  }

  const location = useLocation()
  const isRockets = process.env.REACT_APP_BRAND === 'rockets';

  const { t } = useTranslation(["main", "errors"]);

  useEffect(() => {
    const path = location.pathname;
    if (path === Routes.HOME && isRockets) {
      setActive(t("shop"));
    } else if (path === Routes.PROFILE && isRockets) {
      setActive(t("buy_history"));
    } else if (path === Routes.HOME) {
      setActive(t("main"));
    } else if (path === Routes.SHOP) {
      setActive(t("shop"));
    } else if (path === Routes.QUESTS) {
      setActive(t("quests"));
    } else if (path === Routes.TOURNAMENTS) {
      setActive(t("tournaments"));
    } else if (path === Routes.PROFILE) {
      setActive(t("profile"));
    } else if (path === Routes.HELP) {
      setActive(t("help"));
    } else if (path === Routes.FAQ) {
      setActive(t("faq"));
    }
  }, [location]);

  const handleClose = () => {
    setIsOpen(false);
  }

  const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);


  return (
    <div className={`${styles.container}`}>
      <div className={styles.content} onClick={handleOpen}>
        <div className={styles.title}>
          <MobMenuIcon />
          <div>
            Разделы
          </div>
          <div>
            /
          </div>
          <div className={styles.current}>
            {active}
          </div>
        </div>
        <div className={styles.arrow}>
          <ChevronIcon />
        </div>
      </div>

      {
        isOpen && (
          <div className={styles.menu}>
            <div className={styles.menuContent}>
              {
                isRockets &&
                <div className={styles.menuItem}>
                  <div className={`${styles.menuItemContent} ${active === t("shop") ? styles.active : ''}`}>
                    <NavLink to={Routes.HOME} onClick={handleClose}>{t("shop")}</NavLink>
                  </div>
                </div>
              }
              {
                !isRockets &&
                <>
                  <div className={styles.menuItem}>
                    <div className={`${styles.menuItemContent} ${active === t("main") ? styles.active : ''}`}>
                      <NavLink to={Routes.HOME} onClick={handleClose}>{t("main")}</NavLink>
                    </div>
                  </div>
                  <div className={styles.menuItem}>
                    <div className={`${styles.menuItemContent} ${active === t("shop") ? styles.active : ''}`}>
                      <NavLink to={Routes.SHOP} onClick={handleClose}>{t("shop")}</NavLink>
                    </div>
                  </div>
                  <div className={styles.menuItem}>
                    <div className={`${styles.menuItemContent} ${active === t("quests") ? styles.active : ''}`}>
                      <NavLink to={Routes.QUESTS} onClick={handleClose}>{t("quests")}</NavLink>
                    </div>
                  </div>
                </>
              }
              {
                isAuthenticated && isRockets &&
                <div className={styles.menuItem}>
                  <div className={`${styles.menuItemContent} ${active === t("buy_history") ? styles.active : ''}`}>
                    <NavLink to={Routes.PROFILE} onClick={handleClose}>{t("buy_history")}</NavLink>
                  </div>
                </div>
              }
              {
                !isRockets &&
                <>
                  {
                    isAuthenticated &&
                    <div className={styles.menuItem}>
                      <div className={`${styles.menuItemContent} ${active === t("profile") ? styles.active : ''}`}>
                        <NavLink to={Routes.PROFILE} onClick={handleClose}>{t("profile")}</NavLink>
                      </div>
                    </div>
                  }
                  <div className={styles.menuItem}>
                    <div className={`${styles.menuItemContent} ${active === t("tournaments") ? styles.active : ''}`}>
                      <NavLink to={Routes.TOURNAMENTS} onClick={handleClose}>{t("tournaments")}</NavLink>
                    </div>
                  </div>
                  <div className={styles.menuItem}>
                    <div className={`${styles.menuItemContent} ${active === t("help") ? styles.active : ''}`}>
                      <NavLink to={Routes.HELP} onClick={handleClose}>{t("help")}</NavLink>
                    </div>
                  </div>
                  <div className={styles.menuItem}>
                    <div className={`${styles.menuItemContent} ${active === t("faq") ? styles.active : ''}`}>
                      <NavLink to={Routes.FAQ} onClick={handleClose}>{t("faq")}</NavLink>
                    </div>
                  </div>
                </>
              }
            </div>
          </div>
        )
      }
    </div>
  )
}