export const CoinIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 1C5.03071 1 1 5.03105 1 9.99996C1 14.9689 5.03071 19 10 19C14.9693 19 19 14.9689 19 9.99996C18.9919 5.03105 14.9693 1 10 1ZM15.9147 15.9162C14.3965 17.427 12.3134 18.3649 10 18.3649C7.6867 18.3649 5.60362 17.427 4.08534 15.9162C2.57518 14.3973 1.63657 12.3148 1.63657 9.99996C1.63657 7.68515 2.57518 5.60268 4.08534 4.08374C5.60362 2.57298 7.6867 1.63513 10 1.63513C12.3134 1.63513 14.3965 2.57298 15.9147 4.08374C17.4249 5.60268 18.3635 7.68515 18.3635 9.99996C18.3635 12.3148 17.4249 14.3973 15.9147 15.9162Z" fill="#F2F740" />
      <path d="M11.9259 2.70136H11.9192C11.3003 2.54054 10.6569 2.46085 9.98921 2.46085C9.28217 2.46085 8.59143 2.5568 7.93183 2.73113C7.76525 2.77571 7.66366 2.94462 7.707 3.11216L8.01987 4.34326C8.13094 4.79597 8.54403 5.0906 8.98827 5.0906L10.8709 5.06625C11.3151 5.07434 11.7201 4.77299 11.8393 4.32708L12.1522 3.0811C12.1941 2.91356 12.0926 2.74327 11.9246 2.70007L11.9259 2.70136Z" fill="#F2F740" />
      <path d="M7.93454 17.3H7.94132C8.56028 17.4608 9.20365 17.5405 9.87134 17.5405C10.5784 17.5405 11.2691 17.4446 11.9287 17.2702C12.0952 17.2257 12.1969 17.0568 12.1535 16.8892L11.8407 15.6581C11.7296 15.2054 11.3165 14.9109 10.8723 14.9109L8.98963 14.9351C8.54538 14.927 8.14042 15.2284 8.02123 15.6744L7.70836 16.9203C7.66637 17.0878 7.76795 17.2581 7.9359 17.3014L7.93454 17.3Z" fill="#F2F740" />
      <path d="M17.3097 8.25952V8.25273C17.1513 7.63386 16.9142 7.03247 16.5946 6.44733C16.256 5.82846 15.8429 5.2676 15.373 4.77437C15.2537 4.64873 15.056 4.64194 14.9301 4.75948L13.9955 5.62169C13.6502 5.93517 13.5891 6.43787 13.8018 6.82844L14.7228 8.4663C14.9287 8.85954 15.3878 9.07036 15.8362 8.9622L17.0822 8.64063C17.2502 8.59735 17.3517 8.42706 17.3084 8.25952H17.3097Z" fill="#F2F740" />
      <path d="M2.68893 11.7419V11.7486C2.8474 12.3676 3.08442 12.9689 3.40406 13.554C3.74266 14.173 4.15576 14.7338 4.62573 15.227C4.74492 15.3527 4.94267 15.3594 5.06862 15.2419L6.00316 14.3798C6.34854 14.0662 6.40948 13.5635 6.19685 13.173L5.27585 11.5352C5.06998 11.1419 4.61084 10.9311 4.16253 11.0392L2.91647 11.3608C2.74853 11.404 2.64695 11.5743 2.69029 11.7419H2.68893Z" fill="#F2F740" />
      <path d="M15.3716 15.2446L15.3756 15.2405C15.8172 14.7784 16.2005 14.2568 16.5269 13.677C16.8722 13.0622 17.1269 12.4135 17.2949 11.7541C17.3382 11.5865 17.2379 11.4162 17.0714 11.3717L15.8415 11.0419C15.3919 10.9176 14.9327 11.1338 14.7147 11.5203L13.8167 13.1703C13.5919 13.554 13.6583 14.0541 13.9901 14.3743L14.9273 15.2554C15.0533 15.3743 15.2524 15.3689 15.3716 15.2433V15.2446Z" fill="#F2F740" />
      <path d="M4.62709 4.75681L4.62302 4.76086C4.18149 5.22303 3.7982 5.74466 3.47178 6.32437C3.12641 6.93927 2.87178 7.58791 2.70383 8.24739C2.66049 8.41493 2.76072 8.58522 2.92731 8.6298L4.15711 8.95954C4.60677 9.08387 5.06591 8.86764 5.28398 8.48111L6.18194 6.83111C6.40677 6.44734 6.34041 5.94739 6.00858 5.62711L5.07133 4.74597C4.94538 4.62705 4.74628 4.63247 4.62709 4.75819V4.75681Z" fill="#F2F740" />
      <path d="M12.9946 4.92435C13.2519 5.07571 13.5797 4.9919 13.7327 4.73513C13.8858 4.47836 13.8018 4.15137 13.5445 3.99864C13.2871 3.84598 12.9594 3.93109 12.8063 4.18786C12.6533 4.44463 12.7373 4.77162 12.9946 4.92435Z" fill="#F2F740" />
      <path d="M6.96886 15.0906C6.71152 14.9393 6.38376 15.023 6.23071 15.2798C6.07766 15.5365 6.16164 15.8636 6.41897 16.0162C6.67631 16.169 7.00408 16.0838 7.15712 15.8271C7.31017 15.5703 7.2262 15.2433 6.96886 15.0906Z" fill="#F2F740" />
      <path d="M13.1083 15.1027C12.8538 15.2581 12.7752 15.5865 12.9309 15.8419C13.0867 16.0973 13.4158 16.1757 13.6718 16.0203C13.9278 15.8649 14.005 15.5365 13.8492 15.281C13.6935 15.0257 13.3644 14.9473 13.1083 15.1027Z" fill="#F2F740" />
      <path d="M6.98918 4.91084C7.24381 4.75544 7.32237 4.42707 7.16661 4.17168C7.01085 3.91628 6.68173 3.83789 6.42575 3.9933C6.16977 4.1487 6.09256 4.47707 6.24832 4.73246C6.40408 4.98786 6.7332 5.06625 6.98918 4.91084Z" fill="#F2F740" />
      <path d="M15.9458 9.90142C15.9256 10.1987 16.1477 10.4527 16.4456 10.4744C16.7436 10.496 16.9996 10.2744 17.0212 9.97706C17.0429 9.67976 16.8194 9.42437 16.5215 9.40414C16.2221 9.38384 15.9675 9.60412 15.9458 9.90142Z" fill="#F2F740" />
      <path d="M4.01491 9.97577C4.03522 9.67847 3.8131 9.42445 3.51513 9.40277C3.21716 9.38117 2.96118 9.60283 2.93951 9.90013C2.91784 10.1974 3.14132 10.4528 3.43928 10.473C3.73725 10.4934 3.99324 10.2731 4.01491 9.97577Z" fill="#F2F740" />
      <path d="M10.0285 6.18377C7.90882 6.18377 6.19414 7.90001 6.19414 10.019C6.19414 12.1378 7.91017 13.8541 10.0285 13.8541C12.1468 13.8541 13.8628 12.1378 13.8628 10.019C13.8628 7.90001 12.1468 6.18377 10.0285 6.18377Z" fill="#F2F740" />
    </svg>
  )
}